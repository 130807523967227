const pubApp = () => import('../views/app.vue')
const application = () => import('../views/lkr-project.vue')

const routes = [
    {
        path:'/',
        name:'pub',
        component:application
    },
    {
        path:'/app',
        name:'app',
        component:pubApp
    }
]

export default routes