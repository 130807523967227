import { createApp } from 'vue'
import Index from './components/main-index.vue'
import "@/ui-theme/common.scss"
import ElementPlus from 'element-plus'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import router from './router/index'

const app = createApp(Index)
app.config.globalProperties.$http = httpRequest
app.use(ElementPlus)
app.use(router)
app.mount('#app')